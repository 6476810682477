import React, { Component } from "react";
import Axios from "axios";
import { filter } from "lodash";

class Leaders extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    isLoading: true,
    theData: undefined,
    theLeaders: undefined,
  };

  async componentDidMount() {
    Axios.get(`/team-roster.json`)
      .then((result) => {
        if (!result.data || result.status != "200") return;
        const checkingData = result.data;
        if (typeof checkingData == "object") {
          this.loadTheRoster(checkingData);
        }
      })
      .then(() => {
        Axios.get(`/team-stats.json`).then((result) => {
          if (!result.data || result.status != "200") return;

          const checkingData = result.data;
          if (typeof checkingData == "object") {
            this.loadTheLeaders(checkingData);

            setTimeout(() => {
              this.setState({ isLoading: false });
            }, 250);
          }
        });
      });
  }

  loadTheLeaders(el) {
    if (!el.categories) return;

    const kingStatics = el;

    kingStatics.categories.forEach((element, key) => {
      element.leaders.forEach((eachOneEl, i) => {
        const playerMarker = eachOneEl.athlete["$ref"];
        const grabTheId = playerMarker.slice(
          playerMarker.lastIndexOf("athletes/") + 9,
          playerMarker.lastIndexOf("?lang=en&region=us")
        );

        const matchedTheGuy = filter(this.state.theData, (o) => {
          return o.id == grabTheId;
        })[0];

        if (
          matchedTheGuy &&
          matchedTheGuy.guid &&
          kingStatics.categories[key].leaders[i]
        ) {
          kingStatics.categories[key].leaders[i]["myAthleteInformation"] =
            matchedTheGuy;
        }
      });

      kingStatics.categories[key].leaders = filter(
        kingStatics.categories[key].leaders,
        (o, i) => {
          return o["myAthleteInformation"] && i < 5;
        }
      );
    });

    // console.log("🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀", kingStatics.categories);

    this.setState({ theLeaders: kingStatics.categories });
  }

  loadTheRoster(el) {
    const combined = [];

    el["athletes"].forEach((element) => {
      element.items.forEach((eachOneEl) => {
        const addPlayer = eachOneEl;
        addPlayer["which-side"] = element.position;
        combined.push(addPlayer);
      });
    });

    this.setState({ theData: combined });
  }

  render() {
    const { theData, theLeaders, isLoading } = this.state;

    if (!theData || !theLeaders || isLoading) {
      return (
        <div className="universal-spinner">
          <i className="fa fa-cog fa-spin" />
        </div>
      );
    }

    return (
      <div id="statistics" className="statistics">
        <div className="top">
          <h2>49ers Team Leaders</h2>
          <p>
            The 49ers current leaders in all major categories, updated daily.
          </p>
        </div>

        <div>
          {theLeaders ? (
            <div>
              {theLeaders.map((node, i) => {
                return (
                  <div className={`li-data li-data-type--${node.type}`} key={i}>
                    <div className="category-name">{node.displayName}</div>
                    <div className="category-groupings">
                      {node["leaders"].map((subnode, k) => {
                        return (
                          <div className="person-group" key={k}>
                            {subnode.hasOwnProperty("myAthleteInformation") ? (
                              <div className="person">
                                <div className="img">
                                  <img
                                    src={
                                      subnode["myAthleteInformation"].headshot
                                        .href
                                    }
                                  />
                                </div>

                                {k == 0 ? (
                                  <span className="star star-one">1st</span>
                                ) : null}

                                {k == 1 ? (
                                  <span className="star star-two">2nd</span>
                                ) : null}

                                <div className="content">
                                  <div className="name">
                                    {
                                      subnode["myAthleteInformation"]
                                        .displayName
                                    }
                                  </div>

                                  <div className="age">
                                    Age:{` `}
                                    {subnode["myAthleteInformation"].age} years
                                  </div>

                                  <div className="years">
                                    Experience:{` `}
                                    {subnode["myAthleteInformation"].experience
                                      .years < 1
                                      ? "Rookie"
                                      : `${subnode["myAthleteInformation"].experience.years} years`}
                                  </div>
                                </div>

                                <div className="displayvalue">
                                  {subnode["displayValue"]}{" "}
                                  <span>
                                    {["PYDS", "RYDS", "RECYDS"].includes(
                                      node.abbreviation
                                    )
                                      ? " "
                                      : node.displayName}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default Leaders;
