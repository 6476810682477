import React from "react";
import Head from "../components/Head.js";
import Layout from "../components/Layout.js";
import Leaders from "../components/Leaders.js";

import NewsBlock from "../components/NewsBlock.js";
import RostersBlock from "../components/RostersBlock.js";

const IndexPage = () => {
  return (
    <>
      <Head
        title="Team Statistical Leaders"
        description="The top stat leaders on the team current for SF 49ers."
      />
      <Layout>
        <main>
          <div className="main-wrapper">
            <div className="primary-wrapper">
              <div className="layout-rightsidebar">
                <div className="main">
                  <Leaders />
                </div>
                <div className="right">
                  {/* - */}
                  <NewsBlock />

                  {/* - */}
                  <RostersBlock />
                </div>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default IndexPage;
