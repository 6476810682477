import React, { Component } from "react";
import Axios from "axios";
import { orderBy, filter, uniq, uniqBy, uniqWith, sampleSize } from "lodash";
import moment from "moment";

class NewsBlock extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    theData: undefined,
  };

  async componentDidMount() {
    Axios.get(`/articles.json`).then((result) => {
      if (!result.data) return;

      const checkingData = result.data;
      if (typeof checkingData == "object" && checkingData.length > 5) {
        this.loadTheData(checkingData);
      }
    });
  }

  loadTheData(el) {
    const mrSort = orderBy(el, ["publishedAt"], ["desc"]);
    const properData = mrSort.map((e) => {
      const someNew = e;
      const friendlyDate = moment(someNew.publishedAt).format("MMMM Do YYYY");
      someNew["friendlyDate"] = friendlyDate;
      return someNew;
    });

    // ///// ///// ///// ///
    let scrubbing = filter(properData, (o) => {
      return o.urlToImage;
    });

    scrubbing = uniqBy(scrubbing, "title");
    scrubbing = uniqBy(scrubbing, "publishedAt");
    scrubbing = uniqBy(scrubbing, "url");
    scrubbing = uniqBy(scrubbing, "content");
    scrubbing = uniqBy(scrubbing, "description");

    // - ------ -----

    const iAmSample = sampleSize(scrubbing, 3);

    this.setState({ theData: iAmSample });
  }

  render() {
    const theData = this.state.theData;
    if (!theData) return;

    return (
      <div id="news-block" className="news-block common-grid-block">
        <div className="top">
          <h4>Latest San Francisco 49ers News</h4>
        </div>
        <div className="the-body">
          <div className="gridder-x">
            <div className="">
              {theData.map((node, i) => {
                return (
                  <div
                    className={`element-data element-data-type--${node.type}`}
                    key={i}
                  >
                    <div className="urlToImage">
                      <a href="/news">
                        <img src={node.urlToImage} />
                      </a>
                    </div>
                    <div className="content">
                      <div className="title">
                        <a href="/news">{node.title} </a>
                      </div>
                      {/* <div className="friendlyDate">{node.friendlyDate}</div> */}
                      <div className="description">
                        {node.description.replace(/(<([^>]+)>)/gi, "")} &nbsp;
                        <a href={node.url} target="_blank">
                          read full article
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="link-bottom">
            <a href="/news">
              Read more daily 49ers news{" "}
              <i className="fa-regular fa-hand-point-right"></i>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default NewsBlock;
